<template>
  <el-row style="background: #fff;">
    <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="24" class="ul">
      <div class="dialogCard">
        <div class="subBox">

          <div class="title">地域分布</div>
          <div class="subTitle">更新时间：{{nowTime}}</div>
        </div>

        <el-row>
          <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" class="ul" v-if="geoData">
            <vab-chart :option="optionMap" style="height: 390px;width:100%;" theme="vab-echarts-theme" v-if="tabKey == 'province'" />
            <vab-chart :option="option" style="height: 390px;width:100%;" theme="vab-echarts-theme" v-else-if="geoData.cityDatas.length > 0 && tabKey == 'city'" />
            <vab-chart :option="option" style="height: 390px;width:100%;" theme="vab-echarts-theme" v-else-if="geoData.regionDatas.length > 0 && tabKey == 'region'" />

            <noDataVue v-else style="height: 390px;width:100%;"></noDataVue>

          </el-col>
          <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" class="ul" v-else>

            <noDataVue style="height: 390px;width:100%;"></noDataVue>

          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="24" class="ul">
      <div class="dialogCard">
        <div class="tabDialog">
          <div class="tabBox">
            <div class="tabItem" @click="changeTab(item.key)" :class="[item.key == tabKey ? 'check' : '']" v-for="(item, index) in tabList" :key="index">{{item.name}}</div>
          </div>

        </div>

        <el-table :data="tableData" style="width: 100%;margin-top:42px;" max-height="390">
          <el-table-column prop="name" label="省份" />
          <el-table-column prop="meetingSignUpNum" label="注册人数" />
          <el-table-column prop="meetingSignInNum" label="签到人数" v-if="type == 'EXHIBITION'"/>
          <el-table-column prop="meetingSignInNumProportion" label="签到率" v-if="type == 'EXHIBITION'">
            <template #default="{ row }">
              {{ row.meetingSignInNumProportion}}%
            </template>
          </el-table-column>
        </el-table>

        <!-- <el-pagination background layout="prev, pager, next" :total="1000" /> -->
      </div>
    </el-col>

  </el-row>

</template>

<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'
import _ from 'lodash'
import VabChart from '@/extra/VabChart'
import VabCount from '@/extra/VabCount'
import noDataVue from './noData.vue'

export default defineComponent({
  components: {
    VabChart,
    noDataVue,
    VabCount,
  },
  props: {
    geoDistribution: {
      type: Object,
      default: {},
    },
    nowTime: {
      type: String,
      default: '',
    },
    meetingType: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    watch(
      () => props.geoDistribution,
      (newVal, oldVal) => {
        state.geoData = JSON.parse(JSON.stringify(newVal))
        state.type = props.meetingType
        handleData()
      }
    )
    const state = reactive({
      tabKey: 'region',
      geoData: {
        cityDatas: [],
        regionDatas: [],
      },
      type: '',
      tabList: [
        {
          name: '按地区查看',
          key: 'region',
        },
        {
          name: '按省份查看',
          key: 'province',
        },
        {
          name: '按城市查看',
          key: 'city',
        },
      ],
      tableData: [],
      option: {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let str = params[0].name + '<br/>'
            let value1 =
              `注册人数` +
              `<span style='width:20px;display: inline-block;'></span>` +
              params[0].value +
              '<br/>'
            str += value1
            return str
          },
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: [],
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'right',
              formatter: '{c}', // 使用默认的格式化器显示数值
            },
          },
        ],
      },
      optionMap: {
        autoSize: false,
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            console.log(params)
            let str = params.name + '<br/>'
            let value1 =
              `注册人数` +
              `<span style='width:20px;display: inline-block;'></span>` +
              params.value +
              '<br/>'
            str += value1
            return str
          },
          axisPointer: {
            type: 'shadow',
          },
        },
        visualMap: {
          min: 0,
          max: 10000, // 假设你的数据值范围在 0 到 1000 之间
          left: 'right',
          top: 'bottom',
          text: ['高', '低'],
          calculable: true,
          inRange: {
            color: [
              '#E5F9F9',
              '#CCF3F4',
              '#99E7E9',
              '#66DCDF',
              '#33D0D4',
              '#00C4C9',
            ], // 颜色从浅蓝色渐变到深蓝色
          },
        },
        series: [
          {
            name: '中国',
            type: 'map',
            // center: ['19%', '9%'],
            mapType: 'china', // 指定地图类型为中国地图
            roam: true, // 是否开启鼠标缩放和平移漫游
            zoom: '1',
            label: {
              show: false,
              fontSize: 12,
              textStyle: {
                color: '#FF0000', // 红色
              },
            },
            data: [],
          },
        ],
      },
    })

    const changeTab = (key) => {
      state.tabKey = key
      // emit('refreshData')
      handleData()
    }

    const handleData = () => {
      if (state.tabKey == 'region') {
        state.tableData = state.geoData ? state.geoData.regionDatas : []
        handleBarData(state.geoData ? state.geoData.regionDatas : [])
      }
      if (state.tabKey == 'province') {
        state.tableData = state.geoData ? state.geoData.provinceDatas : []
        handleMapData(state.geoData ? state.geoData.provinceDatas : [])
      }
      if (state.tabKey == 'city') {
        state.tableData = state.geoData ? state.geoData.cityDatas : [] 
        handleBarData(state.geoData ? state.geoData.cityDatas : [] )
      }
    }

    const handleBarData = (val) => {
      let yData = []
      let seriesData = []
      let data = JSON.parse(JSON.stringify(val))
      data = data.splice(0, 10)
      data.reverse()
      data.forEach((v) => {
        yData.push(v.name)
        seriesData.push(v.meetingSignUpNum)
      })
      state.option.yAxis.data = yData
      state.option.series[0].data = seriesData
    }
    const handleMapData = (val) => {
      let seriesData = []
      val.forEach((v) => {
        let obj = {
          name: v.name,
          value: v.meetingSignUpNum,
        }
        seriesData.push(obj)
      })
      state.optionMap.visualMap.max = seriesData[0].value
      state.optionMap.series[0].data = seriesData
    }
    return {
      ...toRefs(state),
      changeTab,
    }
  },
})
</script>


<style lang="scss" scoped>
.dialogCard {
  width: 100%;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .subBox {
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
      margin-bottom: 4px;
    }
    .subTitle {
      font-size: 12px;
      color: #4e5969;
    }
  }
  .tabDialog {
    display: flex;
    justify-content: flex-end;
    .tabBox {
      display: flex;
      padding: 3px 6px;
      background: #f2f3f8;
      box-sizing: border-box;
      height: 28px;
      align-items: center;
      .tabItem {
        margin-right: 12px;
        color: #4e5969;
        font-size: 14px;
        padding: 3px 6px;
        cursor: pointer;
        &.check {
          background: #fff;
          color: #00c4c9;
        }
      }
    }
  }

  .ul {
    margin-top: 15px;
    .smTitle {
      font-size: 16px;
      color: #1d2129;
    }
  }
}
</style>