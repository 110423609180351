
<template>
  <div>
    <el-drawer v-model="drawerFlag" :direction="'rtl'" title="选择风格">
      <div class="drawer">
        <template v-for="(item, index) in modelData" :key="index">
          <div v-if="item.drawer" class="drawer-item" @click="clickDrawer(item, index)">
            <img alt src />
            <span>{{ item.name }}</span>
          </div>
        </template>
      </div>
    </el-drawer>

    <!-- 广告魔方，先选风格 -->
    <!-- <div v-if="res.type == 'advertise'" style="display: flex">
      <div class="decorate-title">
        {{ res.name }}
        <el-button type="primary" @click="seleteType()">选择风格</el-button>
      </div>
    </div> -->
    <!-- 卡片集合 -->
    <div v-if="
        res.options.list &&
        res.options.list.length > 0 &&
        res.type != 'advertise'
      " class="decorate-list">
      <div class="decorate-title">{{ res.name }}</div>

      <div>
        <el-form v-model="res.attributeParams" v-if="res.type == 'tab'">
          <el-form-item label="背景色">
            <el-color-picker v-model="res.attributeParams.background" />
          </el-form-item>
        </el-form>
      </div>

      <!-- 可以拖拽排序 -->
      <draggable class="draggable" ghost-class="ghost" group="model" item-key="index" :list="res.options.list" @change="handleChange" v-if="!res.isRich">
        <template #item="{ element }">
          <div :key="index" class="decorate-item">
            <div class="decorate-item-title">
              <div>卡片{{ element.index + 1 }}</div>

              <el-icon v-if="res.close" color="#e1251b" size="20" @click="closeDecorate(element.index)">
                <close-bold />
              </el-icon>
            </div>

            <!-- 轮播图组件 -->
            <div class="decorate-item-box">
              <!-- 文字录入 -->
              <div v-if="!res.notTitle" class="decorate-view">
                <div class="decorate-view-title" style="flex: 1;">输入标题</div>
                <div class="inp" style="flex: 4;">
                  <el-input v-model="element.title" class="inp" />
                </div>
              </div>

              <!-- 富文本录入 -->
              <div v-if="res.isRich" class="decorate-view">
              </div>

              <!-- 图片上传 -->
              <div v-if="!res.notImg" class="decorate-view">
                <div class="decorate-view-title" style="flex: 1;">照片</div>
                <div style="flex:3;">
                  <img alt class="show-image" :src="element.img" />

                  <div class="tips">
                    建议尺寸
                    <span>{{ element.size }}</span>
                  </div>
                </div>
                <div class="selectBtn" style="flex: 1;">
                  <el-button size="small" type="primary" @click="handleClickImg(element, element.index, 1)">
                    选择
                  </el-button>
                  <el-button circle :icon="Delete" type="danger" @click="deleteClickImg(element, element.index, 1)" />
                </div>
              </div>

              <!-- 视频上传 -->
              <div v-if="!res.notImg" class="decorate-view">
                <div class="decorate-view-title" style="flex: 1;">视频</div>
                <div style="flex:3;">
                  <video :src="element.extraParams?.videoSrc" style="width: 100px; height: 100px" />
                </div>
                <div class="selectBtn" style="flex: 1;">
                  <el-button size="small" type="primary" @click="handleClickImg(element, element.index, 2)">
                    选择
                  </el-button>
                  <el-button circle :icon="Delete" type="danger" @click="deleteClickImg(element, element.index, 2)" />
                </div>
              </div>

              <!-- 视频封面图上传 -->
              <div v-if="!res.notImg" class="decorate-view">
                <div class="decorate-view-title" style="flex: 1;">视频封面</div>
                <div style="flex:3;">
                  <el-image alt class="show-image" :src="element.extraParams?.poster" />
                  <!-- <div class="tips">
                建议尺寸
                <span>{{ item.size }}</span>
              </div> -->
                </div>
                <div class="selectBtn" style="flex: 1;">
                  <el-button size="small" type="primary" @click="handleClickImg(element, element.index, 3)">
                    选择
                  </el-button>
                  <el-button circle :icon="Delete" type="danger" @click="deleteClickImg(element, element.index, 3)" />
                </div>
              </div>

              <!-- 跳转 -->
              <div v-if="!res.notLink" class="decorate-view">
                <div class="decorate-view-title" style="flex: 1;">
                  {{ element.link.length > 0 ? '已选链接' : '链接' }}
                </div>
                <div style="font-size: 12px; color: 999;flex:3">
                  <template v-for="(sItem, sIndex) in element.link" :key="sIndex">
                    <div v-if="sItem.type == 'shop'">
                      店铺： {{ sItem.shopName }}
                    </div>
                    <div v-if="sItem.type == 'active'">
                      活动 {{ sItem.title }}
                    </div>
                    <div v-if="sItem.type == 'Article'">
                      内容页面 {{ sItem.title }}
                    </div>
                    <div v-if="sItem.type == 'page'">
                      机构页面 {{ sItem.name }}
                    </div>
                    <div v-if="sItem.type == 'exhPage'">
                      展会页面 {{ sItem.name }}
                    </div>
                    <div v-if="sItem.type == 'exhibition'">
                      展会 {{ sItem.meetingName }}
                    </div>
                    <div v-if="sItem.type == 'miniPath'">
                      商务应用功能 {{ sItem.name }} {{  sItem.name == '用户注册' ? (sItem.extraParams?.identityName ? sItem.extraParams?.identityName : '')  : '' }}
                      {{  sItem.name == '机构列表' ? (sItem.extraParams?.groupName ? sItem.extraParams?.groupName : '') : '' }}
                    </div>
                    <div v-if="sItem.type == 'link'" class="linkWidth">
                      外部链接 {{ sItem.url }}
                    </div>
                    <div v-if="sItem.type == 'mini'">
                      小程序 {{ sItem.miniData.name }}
                    </div>
                    <div v-if="sItem.type == 'wxVideo'">
                      视频号 {{ sItem.wxVideoType.finderUserName }}
                    </div>
                    <div v-if="sItem.type == 'holdMeet'">
                      会议页面 {{ sItem.name }}
                    </div>
                    <div v-if="sItem.type == 'enterprise'">
                      关联机构 {{ sItem.name }}
                    </div>
                    <div v-if="sItem.type == 'notopen'">
                      暂不开放
                    </div>
                  </template>

                </div>
                <div class="selectBtn" style="flex: 1;">
                  <el-button size="small" type="primary" @click="handleClickFile(element, element.index)">
                    选择
                  </el-button>
                  <el-button circle :icon="Delete" type="danger" @click="deleteLink(element, element.index)" />
                </div>
              </div>

              <!-- 是否为注册链接 -->
              <div v-if="res.type == 'grid'" class="decorate-view">
                <div class="decorate-view-title" style="flex: 1;">是否为注册链接</div>

                <el-radio-group style="flex: 3;" v-model="element.isRegister" @change="isRegisterChange($event, element)">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </div>
              <div class="decorate-view">
                <template v-for="(sItem, sIndex) in element.link" :key="sIndex">
                  <template v-if="sItem.type == 'exhibition'">
                    <div class="decorate-view-title" style="flex: 1;">是否显示tabBar</div>
                    <el-radio-group style="flex: 3;" v-model="element.isShowTabBar" @change="isShowTabBarChange($event, element)">
                      <el-radio :label="'show'">显示</el-radio>
                      <el-radio :label="'hide'">隐藏</el-radio>
                    </el-radio-group>
                  </template>
                </template>
              </div>

            </div>
          </div>
        </template>
      </draggable>

      <div v-if="res.isRich">
        <!-- <Rich :htmlValue="res.options.list[0].richText" :indexValue="res.options.list[0].index" ref="EditorRef" @contentChange="contentChange" /> -->
        <div style="border: 1px solid #ccc">
          <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
          <Editor style="height: 500px; overflow-y: hidden" v-model="res.options.list[0].richText" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
        </div>
      </div>
    </div>

    <!-- <Button type="primary" @click="addDecorate()" ghost>添加</Button> -->

    <el-button v-if="!res.notAdd" type="primary" @click="addDecorate()">
      添加
    </el-button>

    <!-- 选择链接componment -->

    <choose-link ref="chooseLinkRef" :mult-choose="multChooseFlag" @chooseLink="confirmChoose" :enterPriseCode="enterPriseCode" :linkEnterpriseCode="linkEnterpriseCode" />
    <vab-upload ref="vabUploadRef" :limit="1" name="file" :size="2" url="/upload" @addImg="handleAddImg" :options="imgOptions" />

    <!-- 上传视频 -->
    <upLoad-video ref="upLoadVideoRef" @addVideo="handleAddVideo" />
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  watch,
  toRefs,
  shallowRef,
} from 'vue'

import { Delete } from '@element-plus/icons'
import { modelData } from './config.js'
import VabUpload from '@/extra/VabUpload'

import VabQuill from '@/extra/VabQuill'
import upLoadVideo from '@/components/upLoadVideo'
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { upLoadImg } from '@/api/active'
export default defineComponent({
  name: 'Decorate',
  emits: ['refreshList', 'choose-style'],
  components: {
    VabUpload,
    VabQuill,
    upLoadVideo,
    Editor,
    Toolbar,
    chooseLink: defineAsyncComponent(() =>
      import('@/components/chooseLink/chooseLink')
    ),
    Draggable: defineAsyncComponent(() => import('vuedraggable')),
  },
  props: {
    res: {
      default: null,
      type: Object,
    },
    meetingCode: {
      default: '',
      type: String,
    },
    enterPriseCode: {
      default: '',
      type: String,
    },
    linkEnterpriseCode: {
      default: '',
      type: String,
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      radio: false,
      form: {},
      drawerFlag: false,
      modelData,
      chooseLinkDialog: false,
      chooseLinkRef: null,
      vabUploadRef: null,
      upLoadVideoRef: null,
      EditorRef: null,
      multChooseFlag: false,
      isRichTextFlag: false,

      isUpVideoPoster: false, // 上传视频封面flag
      imgOptions: {},
    })
    onMounted(() => {
      console.log(props.res, '123123123asdasdasda')
    })
    watch(
      () => props.res,
      (nval, oval) => {
        props.res.options.list = sortData(
          JSON.parse(JSON.stringify(nval.options.list))
        )
        props.res.options.list.forEach((v) => {
          if (v.extraParams?.isRegister) {
            v.isRegister = true
          } else {
            v.isRegister = false
          }
        })
        // state.EditorRef.handleCreated()
        console.log(nval, 1231, props.res.options.list)
      }
    )
    const closeDecorate = (index) => {
      // 关闭
      console.log(index)
      proxy.res.options.list.splice(index, 1)
      proxy.res.options.list = sortData(proxy.res.options.list)
      emit('refreshList', proxy.res)
    }

    const ready = (editorInstance) => {
      console.log(editorInstance)
      state.form.content = state.form.content
    }

    // 选择广告魔方风格
    const clickDrawer = (item, index) => {
      state.drawerFlag = false
      emit('choose-style', JSON.parse(JSON.stringify(item)))
    }
    const seleteType = () => {
      state.drawerFlag = true
    }

    const addCustomButtom = (editorId) => {
      window.UE.registerUI(
        'test-button',
        function (editor, uiName) {
          // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
          editor.registerCommand(uiName, {
            execCommand: function () {
              editor.execCommand(state['vabUploadRef'].handleShow())
            },
          })

          // 创建一个 button
          var btn = new window.UE.ui.Button({
            // 按钮的名字
            name: '图片',
            // 提示
            title: '上传图片',
            // 需要添加的额外样式，可指定 icon 图标，图标路径参考常见问题 2
            cssRules:
              "background-image: url('https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/GDT/mini/img/upImg.png') !important;background-size: cover;",
            // 点击时执行的命令
            onclick: function () {
              // 这里可以不用执行命令，做你自己的操作也可
              editor.execCommand(uiName)
            },
          })

          // 当点到编辑内容上时，按钮要做的状态反射
          editor.addListener('selectionchange', function () {
            var state = editor.queryCommandState(uiName)
            if (state === -1) {
              btn.setDisabled(true)
              btn.setChecked(false)
            } else {
              btn.setDisabled(false)
              btn.setChecked(state)
            }
          })

          // 因为你是添加 button，所以需要返回这个 button
          return btn
        },
        0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
        editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
      )
    }

    const addDecorate = () => {
      let data = {
        img:
          props.res.type === 'carousel'
            ? 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/admin/img/banner1.jpg'
            : 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/admin/img/grid1.jpg',
        url: '',
        link: '',
        title: '标题',
        index: props.res.options.list.length,
        size: props.res.type === 'carousel' ? '750*350' : '88*88 (1:1)',
        extraParams: {},
      }
      proxy.res.options.list.push(data)
      emit('refreshList', proxy.res)
    }

    const handleClickFile = (item, index) => {
      state.chooseItem = item
      if (props.res.type === 'tab') {
        state.multChooseFlag = true
      } else {
        state.multChooseFlag = false
      }
      state.chooseLinkRef.showDialog(
        item.link == '' ? [] : item.link,
        props.meetingCode
      )
    }
    const deleteLink = (item, index) => {
      item.link = []
    }

    const handleClickImg = (item, index, type) => {
      console.log(item)
      state.imgOptions = item.options
      state.chooseItem = item
      if (type == 1) {
        state.isUpVideoPoster = false
        state.vabUploadRef.handleShow()
      } else if (type == 3) {
        state.isUpVideoPoster = true
        state.vabUploadRef.handleShow()
      } else {
        state.upLoadVideoRef.handleShow()
      }
    }
    const deleteClickImg = (item, index, type) => {
      if (type == 2) {
        item.extraParams.videoSrc = ''
        item.extraParams.isVideo = false
      } else if (type == 1) {
        item.img = ''
      } else {
        item.extraParams.poster = ''
      }
    }

    const confirmChoose = (val) => {
      state.chooseItem.link = val
      state.chooseLinkDialog = false
      console.log(val, state.chooseItem, '====>??hvv')
    }

    const handleAddImg = (val) => {
      if (state.isRichTextFlag) {
        console.log(props.res, val)
        props.res.options.list[0].richText += `<img src="${val}" />`
      } else if (state.isUpVideoPoster) {
        state.chooseItem.extraParams = Object.assign(
          state.chooseItem.extraParams,
          {
            poster: val,
          }
        )
      } else {
        state.chooseItem.img = val
      }
    }

    // 元素新增
    const handleChange = (evt) => {
      console.log(evt)
      // 元素排序
      if (evt.moved) {
        props.res.options.list = sortData(props.res.options.list)
        console.log(props.res.options.list)
      }
    }
    // 元素排序
    const sortData = (array) => {
      for (let i in array) {
        array[i].index = Number(i)
      }
      return array
    }

    const handleAddVideo = (val) => {
      state.chooseItem.extraParams = Object.assign(
        state.chooseItem.extraParams,
        {
          videoSrc: val,
          isVideo: true,
        }
      )
    }

    const isRegisterChange = (e, item) => {
      item.extraParams = {
        isRegister: e,
      }
      item.isRegister = e
    }
    const isShowTabBarChange = (e, item) => {
      item.extraParams = {
        isShowTabBar: e,
      }
      item.isShowTabBar = e
    }

    const contentChange = (value, index) => {
      console.log(value, index)
      props.res.options.list[index].richText = value
    }

    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    const toolbarConfig = {
      excludeKeys: ['fullScreen', 'uploadVideo'],
    }
    const editorConfig = {
      placeholder: '请输入内容...',
      MENU_CONF: {
        uploadImage: {
          async customUpload(file, insertFn) {
            console.log(file)
            let upData = new FormData()
            upData.append('file', file)
            const { data } = await upLoadImg(upData)
            insertFn(data, '图片', '')
          },
        },
      },
    }

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
      console.log(editorRef.value.getAllMenuKeys())
    }

    const customUpload = () => {
      console.log('dddd')
    }

    return {
      ...toRefs(state),
      closeDecorate,
      clickDrawer,
      seleteType,
      addDecorate,
      handleClickFile,
      confirmChoose,
      handleClickImg,
      deleteClickImg,
      handleAddImg,
      handleAddVideo,
      handleChange,
      Delete,
      isRegisterChange,
      isShowTabBarChange,
      contentChange,
      ready,
      editorRef,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      deleteLink,
    }
  },
})
</script>
<style lang="scss" scoped>
#edui95_iframeholder {
  height: 400px;
}
.decorate {
  height: calc(100vh - 120px);
  padding: 0 20px;
  padding-bottom: 120px;
  overflow-y: auto;
}
.decorate-title {
  height: 50px;
  font-weight: bold;
  line-height: 50px;
}
.decorate-list {
  overflow: hidden;
}
.decorate-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.decorate-item-box {
  padding: 10px;
  background: #fff;
  border: 1px solid #ededed;
}
.decorate-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 10px;
  line-height: 40px;
}
.decorate-view-title {
  margin-right: 10px;
}
.decorate-item {
  margin-bottom: 20px;
  background: #ededed;
  border-top-left-radius: 0.4em;
  border-top-right-radius: 0.4em;
}
.show-image {
  max-width: 50px;
}
.inp {
  width: calc(100% - 80px);
}
.linkWidth {
  width: 200px;
  word-break: break-all;
}

.drawer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > .drawer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 60px;
    margin-right: 14px;
    margin-bottom: 14px;
    color: #9254de;
    cursor: pointer;
    background: #f9f0ff;
    border: 1px solid #ededed;
    border-radius: 0.8em;
  }
}
</style>
